import React from "react";
import styled from "styled-components";
import { Skeleton } from "@mui/material";

const TableShimmer = styled.div`
  .MuiSkeleton-root {
    width: 100%;
    background: #f6f7f8;

    &.MuiSkeleton-rect {
      margin-top: 0px;
      margin-bottom: 25px;
    }
    &.MuiSkeleton-text {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
`;

function GTableShimmer() {
  return (
    <TableShimmer>
      <div>
        <Skeleton variant="text" height={90} animation="wave" />
        <Skeleton variant="rect" width="90%" height={50} animation="wave" />
        <Skeleton variant="rect" width="90%" height={50} animation="wave" />
        <Skeleton variant="rect" width="90%" height={50} animation="wave" />
        <br />
        <br />
        <Skeleton variant="text" height={40} animation="wave" />
      </div>
    </TableShimmer>
  );
}

export default GTableShimmer;
