import React, { useEffect, useRef, useState } from "react";
import "../assets/css/tooltip.css";

function Tooltip({ title, position, children, id }) {
  const node = useRef();
  const [isVisible, setState] = useState(false);
  const handleClick = ({ target }) => {
    if (node.current.contains(target)) {
      // inside click
      return;
    }
    // outside click
    setState(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div
      className="tooltipContainer"
      data-testid={"tooltip" + id}
      ref={node}
      // onClick={() => setState(!isVisible)}
      onMouseEnter={() => setState(true)}
      onMouseLeave={() => setState(false)}
      style={{ display: "inline-block", verticalAlign: "middle" }}
    >
      <div data-testid={"tooltip-placeholder" + id}>{children}</div>

      {isVisible && (
        <div
          // className={cx("tooltipContent", [position])}
          data-testid={"tooltip-content" + id}
          style={{ minWidth: "200px" }}
        >
          <span className="arrow"></span>
          {title}
        </div>
      )}
    </div>
  );
}

Tooltip.defaultProps = {
  position: "right",
};

export default Tooltip;
