import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/untick.svg";
import { GCardShimmer } from "../Elements/gShimmer";
import GButton from "../Elements/GButton";
import CardPayment from "./cardPayments";
import GText from "../Elements/GText";
import useWindowSize from "../DataFactory/useWindowSize";

const PaymentDiv = styled.div`
  padding: 0 20px;
  display: flex;
  .inputDiv {
    display: inline-flex;
    width: 100%;
    cursor: pointer;
    align-items: center;
    border-radius: 5px;
    border-bottom: 1px solid #f4f5f9;
    padding: 15px 10px;
    position: relative;
    box-sizing: border-box;

    label {
      font-weight: 500;
      cursor: pointer;
      text-transform: capitalize;
      color: #333;
      margin: 0;
      font-size: 15px;
      vertical-align: top;
      width: calc(100% - 65px);
      p {
        color: #555;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        padding-top: 3px;
      }
      span {
        font-weight: 400;
        padding: 5px 0;
        position: relative;
      }
    }
    svg {
      font-size: 0.8rem;
      width: 20px;
      height: 20px;
    }

    img {
      width: 25px;
      margin-right: 10px;
    }

    span {
      display: inline-flex;
      margin-left: auto;
      &.active svg {
        path {
          fill: ${({ theme }) => theme.body};
        }
      }
    }
  }
`;

const CashCard = styled.div`
  border: 1px solid #f2f2f2;
  padding: 15px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;

  &.active {
    border: 1px solid ${({ theme }) => theme.body};
  }

  h4 {
    display: flex;
    align-items: center;
    img {
      width: 35px;
    }
  }
`;

export default function PaymentMethod(props) {
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [selectedGateway, setSelectedGateway] = useState(
    props.setCPaymentMethod || {}
  );
  const [cardPayment, setCardPayment] = useState(
    props.paymentGateways?.card || {}
  );
  const [paymentGateways, setPaymentGateways] = useState(
    props.paymentGateways?.gateways || []
  );

  useEffect(() => {
    setPaymentGateways(props.paymentGateways?.gateways || []);
    if (!props.paymentGateways?.card?.identifier) {
      setSelectedGateway(props.paymentGateways?.gateways[0]);
      props.onSelecteGateway(props.paymentGateways?.gateways[0]);
    }
    if (props.paymentGateways?.card?.identifier) {
      setCardPayment(props.paymentGateways?.card);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props.paymentGateways]);

  return (
    <>
      {loading == true ? (
        <>
          <br />
          <GCardShimmer type="single" height={60}></GCardShimmer>
        </>
      ) : (
        <PaymentDiv>
          <div
            style={{
              width: "100%",
              display: "inline-block",
              boxSizing: "border-box",
            }}
          >
            {paymentGateways &&
              paymentGateways.map((gateway, i) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      className={
                        selectedGateway?.identifier === gateway.identifier
                          ? "inputDiv active"
                          : "inputDiv"
                      }
                      onClick={() => {
                        setSelectedGateway(gateway);
                        if (props.paymentGateways?.card) {
                          cardPayment.card_id = "";
                          setCardPayment({ ...cardPayment });
                        }
                        props.onSelecteGateway(gateway);
                      }}
                    >
                      <input
                        name="option_payment_method"
                        type="radio"
                        id={"v_option_" + gateway.identifier}
                        value={gateway.identifier}
                        style={{ display: "none" }}
                        defaultChecked={
                          selectedGateway?.identifier === gateway.identifier
                        }
                      />
                      <img
                        src={
                          process.env.REACT_APP_API_IMAGE_URL +
                          "/assets/" +
                          gateway.identifier +
                          ".png"
                        }
                        alt={gateway.identifier}
                        width="22px"
                        height="auto"
                      />
                      &nbsp;&nbsp;
                      <label htmlFor={"v_option_" + gateway.identifier}>
                        {gateway.title}
                        {gateway.identifier === "cash" ? (
                          <GText text={"Please keep the change ready."} />
                        ) : (
                          ""
                        )}
                      </label>
                      {selectedGateway?.identifier === gateway.identifier ? (
                        <span className="active">
                          <Check style={{ fontSize: "1rem" }} />
                        </span>
                      ) : (
                        <span>
                          <Uncheck style={{ fontSize: "1rem" }} />
                        </span>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            {props.paymentGateways?.card?.identifier ? (
              <>
                <hr
                  style={{
                    margin: "5px 0",
                    border: "0",
                    // borderBottom: "1px solid #e2e2e2",
                  }}
                />

                <CardPayment
                  cardPayment={cardPayment}
                  paymentGateways={props.paymentGateways}
                  onSelecteCard={(card) => {
                    setSelectedGateway({});
                    cardPayment.card_id = card.id;
                    props.onSelecteGateway({ ...cardPayment });
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </PaymentDiv>
      )}
    </>
  );
}
