import React, { useState, useEffect, useContext } from "react";
import { FlatList, View, Platform } from "react-native";
import { SettingsContext } from "../DataFactory/useSettings";
import OrderInfo from "./orderDetail";
import useDataFctory from "../useDataFactory";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import GRouteAddress from "../Elements/GRouteAddress";
import GInfo from "../Elements/gInfo";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import { useLocation } from "react-router-dom";

export default function Orders(props) {
  const location = useLocation();
  const { width } = useWindowSize();
  const { settings } = useContext(SettingsContext);
  const [orderInfovisible, setOrderInfovisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(
    location?.state?.order_id || ""
  );
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("order", true);

  console.log(data);

  useEffect(() => {
    setBtnDisabled(false);
  }, [data]);

  useEffect(() => {
    console.log(location?.state);
    if (location?.state?.order_id) {
      setSelectedOrderId(location?.state?.order_id);
      setTimeout(function () {
        setOrderInfovisible(true);
      }, 1000);
    }
  }, []);

  let count = Math.round(width / (Platform.OS === "web" ? 600 : 300));

  return (
    <React.Fragment>
      <GView
        style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
      >
        <GText g2="true" theme bold text={"All Orders"} />
        {data.length > 0 && (
          <>
            &nbsp;
            <GText
              g5
              med
              semi
              text={
                pagination.total > 1
                  ? " (" + pagination?.total + " Orders)"
                  : " (" + pagination?.total + " Order)"
              }
              style={{ fontStyle: "italic" }}
            />
          </>
        )}
      </GView>
      {loading === true ? (
        <Shimmer></Shimmer>
      ) : (
        <React.Fragment>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <FlatList
              key={
                width <= 767
                  ? "s"
                  : width > 767 && width < 900
                  ? "m"
                  : width >= 900 && width < 1500
                  ? "l"
                  : "xl"
              }
              initialNumToRender={data.length}
              contentContainerStyle={{ flex: 1 }}
              keyExtractor={(data) => data.id}
              data={data}
              numColumns={count}
              renderItem={({ item }) => (
                <div style={{ flex: 1 / count }}>
                  <div
                    onClick={() => {
                      setSelectedOrderId(item?.id);
                      setOrderInfovisible(true);
                    }}
                    style={{
                      background: "#fff",
                      padding: "12px 12px 20px",
                      margin: "8px",
                      cursor: "pointer",
                      border: "1px solid #f2f2f2",
                      height: "calc(100% - 54px)",
                    }}
                  >
                    <GInfo
                      title={"#" + item?.id + " - " + item?.status?.title}
                      rightTitle={
                        settings.currency_symbol + item?.amount_display
                      }
                      subtitle2={item?.created}
                      titleTheme={true}
                      titleStyle={{
                        color: "#" + item.status?.color1,
                        background: "#" + item.status?.color2,
                        display: "inline-block",
                        padding: "2px 12px",
                        fontSize: "15px",
                        border: "1px solid #" + item.status?.color1,
                        textTransform: "uppercase",
                      }}
                    />
                    <hr
                      style={{
                        borderTop: "0",
                        borderBottom: "1px dashed #bbb",
                      }}
                    />
                    <div
                      style={{
                        maxHeight: "200px",
                        overflow: "auto",
                      }}
                    >
                      <GRouteAddress task={item.tasks} />
                    </div>
                  </div>
                </div>
              )}
              ListEmptyComponent={Placeholder}
            />
          </View>

          {pagination.next_page_url && (
            <GButton
              loading={btnDisabled}
              variant="condensed"
              children="Load More"
              type="button"
              onClick={() => {
                setBtnDisabled(true);
                loadMore();
              }}
              style={{
                margin: "10px auto",
                width: "150px",
                justifyContent: "center",
                display: "flex",
              }}
            />
          )}
        </React.Fragment>
      )}
      <Modal
        isOpen={orderInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setOrderInfovisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Detail Modal"
      >
        <OrderInfo
          orderInfovisible={orderInfovisible}
          setOrderInfovisible={setOrderInfovisible}
          linkedId={selectedOrderId}
          settings={settings}
          onCancelOrder={() => refreshData()}
        />
      </Modal>
    </React.Fragment>
  );
}
