import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import RedeemCode from "../Components/redeemPoints";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";

const InviteDiv = styled.div`
  margin: 10px;
  padding: 0 10px;
  hr {
    border: 0;
    border-bottom: 1px solid #f2f2f2;
    margin: 15px 0;
  }
  h3 {
    border: 1px dashed #a2a2a2;
    margin: 5px 0;
    padding: 8px 25px 7px;
    color: #333;
    font-size: 17px;
    line-height: 28px;
    display: inline-block;
    min-width: 150px;
    text-align: center;
    cursor: pointer;
  }
`;

export default function Invite() {
  const { settings, refreshSettings } = useContext(SettingsContext);
  const [redeemCodeVisible, setRedeemCodeVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();
  console.log(settings);

  return (
    <div>
      {settings?.redeemed === false && (
        <GButton
          variant="outlined"
          children="Redeem"
          onClick={() => {
            setRedeemCodeVisible(true);
          }}
          style={{
            float: "right",
            padding: "0.5rem 1.5rem",
          }}
        />
      )}
      <GText g2="true" theme bold text={"Invite & Earn"} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px 0 ",
          margin: "10px 0",
        }}
      >
        <GView
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: width <= 767 ? "column" : "row",
          }}
        >
          <GView
            style={{
              padding: "10px 25px 20px",
              minWidth: width <= 991 ? "150px" : "200px",
            }}
          >
            <GText
              h5
              med
              text={"Available Points"}
              style={{ margin: "10px 0", letterSpacing: "0" }}
            />

            {settings?.user?.wallet ? (
              <GText g3 semi text={settings?.user?.wallet} />
            ) : (
              <GText text={"You have 0 wallet points"} />
            )}
          </GView>
          <GView
            style={{
              flex: "1 1 1%",
              borderLeft: width <= 767 ? "0" : "1px solid #f2f2f2",
              borderTop: width <= 767 ? "1px solid #f2f2f2" : "0",
            }}
          >
            <InviteDiv>
              <GText
                g4
                semi
                text={"Earn wallet points"}
                style={{ textTransform: "uppercase", paddingTop: "10px" }}
              />
              <GText
                dark
                g5
                text={
                  "Invite friends & earn points when they sign up using your referral code."
                }
                style={{ letterSpacing: "0.2px", margin: "7px 0" }}
              />
              <hr />

              <GText
                text={"Share your referral code"}
                style={{ letterSpacing: "0", margin: "7px 0" }}
              />
              <h3
                onClick={() => {
                  navigator.clipboard.writeText(settings?.user?.referral_code);
                  enqueueSnackbar("Copied : " + settings?.user?.referral_code);
                }}
              >
                {settings?.user?.referral_code}
              </h3>
            </InviteDiv>
          </GView>
        </GView>
      </GView>

      <Modal
        isOpen={redeemCodeVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setRedeemCodeVisible(false)}
        style={{
          content: {
            width: "350px",
            top: "0%",
            left: "0%",
            right: "0",
            bottom: "0",
            margin: "auto",
            height: "270px",
          },
        }}
        contentLabel="Redeem code Modal"
      >
        <RedeemCode
          redeemCodeVisible={redeemCodeVisible}
          setRedeemCodeVisible={setRedeemCodeVisible}
          onRedeem={() => {
            refreshSettings();
          }}
        />
      </Modal>
    </div>
  );
}
