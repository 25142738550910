import React, { useState, useContext } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GMap from "../Elements/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

const addressTitle = [
  {
    key: "HOME",
    value: "home",
  },
  {
    key: "OFFICE",
    value: "work",
  },
  {
    key: "OTHER",
    value: "other",
  },
];

export default function CreateAddress(props) {
  const { settings } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [addressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.lat),
          lng: parseFloat(props.data.lng),
        }
      : { lat: 30.7046, lng: 76.7179 }
  );
  const initialValues = {
    type: "saved",
    customer_id: "",
    title: !props.isEmpty ? props.data.title : "home",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    rec_name: !props.isEmpty ? props.data.rec_name : "",
    rec_phone: !props.isEmpty ? props.data.rec_phone : "",
    lat: !props.isEmpty ? props.data.lat : "",
    lng: !props.isEmpty ? props.data.lng : "",
    notes: !props.isEmpty ? props.data.notes : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    values.customer_id = settings?.user?.id;
    values.title = values.title == "other" ? values.other_title : values.title;
    console.log(values);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "c/addresses/" + props.data.id
      : "c/addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setAdvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required("Required"),
    // city: Yup.string().required("Required"),
    // state: Yup.string().required("Required"),
    // country: Yup.string().required("Required"),
    // zipcode: Yup.string()
    //   .required("Required")
    //   .min(4, "zipcode must be at least 4 digits")
    //   .max(10),
    rec_name: Yup.string().required("Required"),
    rec_phone: Yup.string().required("Required"),
  });

  if (props.advisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setAdvisible(false)}
        />
        {props.isEmpty === true ? "Create" : "Edit"} Address
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          maxHeight: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={addressLocation}
                search={true}
                map={true}
                height="30vh"
                type="createAddress"
                onChange={(address, loc) => {
                  values.lat = loc.lat();
                  values.lng = loc.lng();
                  values.line1 = address.address;
                  values.city = address.city;
                  values.state = address.state;
                  values.country = address.country;
                  values.zipcode = address.zipcode;
                  setFieldValue("line1", address.address);
                }}
                padding={true}
                placeholder={"Search by Postcode, Area, Street or Landmark.."}
              />
              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  boxSizing: "border-box",
                  fontSize: "14px",
                  margin: "3px 0",
                }}
              />
              {values.title === "other" && (
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Save as (Ex. Farmhouse, danny's home)"
                  name="other_title"
                  className="noBorder"
                />
              )}
              <FormikControl
                control="input"
                type="text"
                placeholder="House / Flat / block No. *"
                name="line1"
                className="noBorder"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Landmark / Extra Details"
                name="line2"
                className="noBorder"
              />
              <GText
                g6
                med
                semi
                text="Other Details"
                style={{
                  padding: "15px 15px 10px",
                  textTransform: "uppercase",
                  fontSize: "14px",
                }}
              />
              {/* <FormikControl
                control="input"
                type="text"
                placeholder="City"
                name="city"
                maxLength={30}
                onChange={(event) => {
                  const re = /^[A-Za-z\s\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("city", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="State"
                name="state"
                maxLength={15}
                onChange={(event) => {
                  const re = /^[A-Za-z\s\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("state", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Country"
                name="country"
                maxLength={51}
                onChange={(event) => {
                  const re = /^[A-Za-z\s\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("country", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Zipcode"
                name="zipcode"
                maxLength={10}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("zipcode", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                }}
              /> */}
              <FormikControl
                control="input"
                type="text"
                placeholder="Contact Name"
                name="rec_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_name", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Contact Phone"
                name="rec_phone"
                maxLength={13}
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_phone", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                }}
              />
              <FormikControl
                control="input"
                as="textarea"
                placeholder="Address Notes (Optional)"
                name="notes"
              />
              <br />
              <GButton
                variant="condensed"
                loading={isBtnLoading}
                children={!props.isEmpty ? "Update" : "Create"}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px 15px" }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
