import React from "react";
import Input from "./input";
import NumberInput from "./numberInput";
import Textarea from "./textarea";
import Selectbox from "./selectbox";
import SelectGroupBox from "./selectGroupBox";
import RadioButtons from "./radioButton";
import CheckBoxes from "./checkBoxGroup";
// import DatePicker from "./datePicker";

function formikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "numberInput":
      return <NumberInput {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Selectbox {...rest} />;
    case "selectGroup":
      return <SelectGroupBox {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkbox":
      return <CheckBoxes {...rest} />;
    // case "date":
    //   return <DatePicker {...rest} />;
    default:
      return null;
  }
}

export default formikControl;
