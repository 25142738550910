import React, { useState } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { ApiLoginService } from "../services";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

export default function NewPassword(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    username: props.data.username,
    otp: props.data.otp,
    password: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    ApiLoginService("POST", "c/reset-password", values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.onChange();
        props.setNpvisible(false);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Required"),
  });

  if (props.npvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setNpvisible(false)}
        />
        Reset Password
      </div>
      <div className="modal-body">
        <h4 style={{ margin: "5px 15px 10px" }}>Enter New Password</h4>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ isValid }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                placeholder="New Password"
                name="password"
                style={{ height: "45px" }}
              />
              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "92%",
                }}
                children="Reset Password"
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
