import React, { useState, useEffect, useContext } from "react";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import VehicleSection from "../Components/orderVehicles";
import OrderStops from "../Components/orderStops";
// import MapWithMarkers from "../Components/orderStopsMap";
import GTaxiMap from "../Components/orderStopsMap";
import FormSection from "../Components/orderForm";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { SettingsContext } from "../DataFactory/useSettings";
import GButton from "../Elements/GButton";
import GdatePicker from "../Elements/gDatePicker";import moment from "moment";
import CheckoutForm from "./orderCheckout";
import Modal from "react-modal";
import {
  GCardShimmer,
  GRoundShimmer,
  GLineShimmer,
} from "../Elements/gShimmer";
import styled from "styled-components";

const DateTimePicker = styled.div`
  .react-datepicker__input-container {
    input {
      outline: none;
      border: none;
      font-size: 14px;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 12px;
      border: 1px solid #e2e2e2;
    }
    .react-datepicker-popper[data-placement^="bottom"] {
      // left: ${window.innerWidth > 767 ? "-65px!important" : "-65px !important"};
    }
  }
  .react-datepicker-popper[data-placement^="bottom"] {
    // left: ${window.innerWidth > 767 ? "-65px!important" : "-65px !important"};
  }
`;


const OrderCreate = () => {
  const [currentHour,setCurrentHours] = useState(new Date().getHours())
  const[currentMins,setCurrentMins] = useState(new Date().getMinutes());
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const { addresses, fields, vehicle_types } = formData;
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = useContext(SettingsContext);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const[scheduleTime,setScheduleTime] = useState("")
  const [orderData, setOrderData] = useState({
    stops: [],
    fields: [],
    points: false,
    tip: 0,
    vehicle_type_id: selectedVehicleType,
  });
  const [checkoutVisible, setCheckoutVisible] = useState(false);

  useEffect(() => {
    ApiService({ method: "OPTIONS", route: "c/orders" })
      .then((response) => {
        console.log("gh" + response);
        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setFormData(response.data.data);
            orderData.fields = response.data?.data?.fields || [];
            setOrderData({ ...orderData });
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(selectedVehicleType);
    orderData.vehicle_type_id = selectedVehicleType;
    orderData.fields = formFields.length > 0 ? formFields : fields;
    setOrderData({ ...orderData });
  }, [selectedVehicleType, formFields]);

  useEffect(() => {
    orderData.stops = places;
    setOrderData({ ...orderData });
  }, [places]);

  // useEffect(() => {
  //   console.log(orderData);
  //   setIsFormValidate(false);
  //   if (orderData.vehicle_type_id) {
  //     if (orderData.stops.length >= 2) {
  //       if (orderData.fields.length >= 0) {
  //         let validate = true;
  //         orderData.fields.map((f) => {
  //           console.log(f);
  //           if (f.required === 1) {
  //             if (!f.value) {
  //               validate = false;
  //             }
  //           }
  //         });
  //         setIsFormValidate(validate);
  //       }
  //     }
  //   }
  // }, [orderData]);

  function showValidations() {
    if (!orderData.vehicle_type_id) {
      enqueueSnackbar("Choose Vehicle is requied");
      return false;
    }
    if (orderData.stops.length == 0) {
      enqueueSnackbar("Choose Pickup address");
      return false;
    }
    if (orderData.stops.length > 0 && orderData.stops.length < 2) {
      enqueueSnackbar("Choose Delivery address");
      return false;
    }
    if(!scheduleTime){
      enqueueSnackbar("Choose Schedule Time");
      return false;
    }
    if(scheduleTime){
      orderData.stops.map((s)=>{
        s.complete_after = moment(scheduleTime).format("YYYY-MM-DD HH:mm:ss");
      })
    }
    if (orderData.fields.length >= 0) {
      let validate = true;
      let m = "";
      orderData.fields.map((f) => {
        console.log(f);
        if (f.required === 1) {
          if (!f.value) {
            validate = false;
            m = f;
          }
        }
      });
      if (validate == false) {
        enqueueSnackbar(m.title + " is required");
        return false;
      }
    }
    return true;
  }

  return (
    <GView
      style={{
        display: "flex",
        flexDirection: width <= 767 ? "column" : "row",
      }}
    >
      {width <= 767 && (
        <GView
          style={{
            width: "100%",
          }}
        >
          <GTaxiMap
            center={{ lat: 30.70586, lng: 76.708282 }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            style={{ position: "relative" }}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GView>
      )}
      <GView
        style={{
          width: width <= 767 ? "100%" : "30%",
          minWidth: "250px",
          background: "#f5f6f9",
        }}
      >
        <GView
          style={{
            height: width > 767 ? "calc(100vh - 175px)" : "auto",
            overflow: "overlay",
            padding: "10px 20px 10px",
          }}
        >
          {isLoading == true ? (
            <>
              <GLineShimmer />
              <GRoundShimmer></GRoundShimmer>
              <GLineShimmer />
              <GCardShimmer type={"single"} color={"#DBDCDD"}></GCardShimmer>
            </>
          ) : (
            <>
              {vehicle_types && vehicle_types.length > 0 && (
                <VehicleSection
                  data={vehicle_types}
                  onSelect={(id) => setSelectedVehicleType(id)}
                />
              )}

              <GView style={{ margin: "20px 0" }}>
                <GText
                  g4
                  semi
                  text={"Select Stops"}
                  style={{ margin: "10px 0" }}
                />
                {addresses && (
                  <OrderStops
                    setPlaces={setPlaces}
                    address={addresses}
                    // onChange={(data) => {
                    //   console.log("data", data);
                    //   setPlaces(data);
                    // }}
                  />
                )}
              </GView>

              <GView style={{ margin: "20px 0" }}>
                <GText
                  g4
                  semi
                  text={"Schedule Time"}
                  style={{ margin: "10px 0" }}
                />
                <DateTimePicker>
                 <GdatePicker
                    showTimeSelect
                    id="schedule_time"
                    name="schedule_time"
                    value={
                     scheduleTime
                        ? new Date(scheduleTime)
                        : null
                    }
                    minDate={new Date()}
                    minTime={new Date(new Date().setHours(currentHour, currentMins, 0, 0))}
                    maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                    format="MMM dd yyyy, h:mm aa"
                    onChange={(date) => {
                      console.log(date);

                      // const isSelectedDateToday = new Date().getDate() === date.getDate();
                      const isSelectedDateInFuture = new Date().getDate() < date.getDate();
                      if (isSelectedDateInFuture) {
                        setCurrentHours(0);
                        setCurrentMins(0);
                      }else{
                        setCurrentMins(new Date().getMinutes());
                       setCurrentHours(new Date().getHours());
                      }

                       setScheduleTime(date);
                      // let sTime = moment(date).format(
                      //   "YYYY-MM-DD HH:mm:ss"
                      // );
                      // setScheduleTime(sTime);
                    }}
                  />
                  </DateTimePicker>
                </GView>

              {fields && fields.length > 0 && (
                <FormSection
                  data={fields}
                  settings={settings}
                  onChange={(fields) => {
                    setFormFields(fields);
                  }}
                />
              )}
            </>
          )}
        </GView>
        {isLoading != true && (
          <GButton
            variant="condensed"
            // disabled={!isFormValidate}
            children="Proceed to Payment"
            style={{
              width: "calc(100% - 50px)",
              margin: "20px 25px",
              textTransfoem: "uppercase",
              fontSize: "14px",
              padding: "0.8em",
              fontWeight: "550",
              borderRadius: "2px",
            }}
            onClick={() => {
              let v = showValidations();
              if (v == true) {
                setCheckoutVisible(true);
              }
            }}
          />
        )}
      </GView>

      {width > 767 && (
        <GView
          style={{
            width: "70%",
            position: "relative",
          }}
        >
          <GTaxiMap
            center={{ lat: 30.70586, lng: 76.708282 }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GView>
      )}

      <Modal
        isOpen={checkoutVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCheckoutVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Checkout Modal"
      >
        <CheckoutForm
        scheduleTime={scheduleTime}
          checkoutVisible={checkoutVisible}
          setCheckoutVisible={setCheckoutVisible}
          data={{ ...orderData }}
        />
      </Modal>
    </GView>
  );
};

export default OrderCreate;
