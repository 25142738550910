import React, { useContext, useState, useRef } from "react";
import { LogoutService, ImgUrl } from "../services";
import GView from "../Elements/GView";
import styled from "styled-components";
import ChangePassword from "../forms/ChangePassword";
import EditProfile from "../forms/editProfile";
import ForgotPassword from "../forms/forgotPassword";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import { Link } from "react-router-dom";
import GAvtar from "../Elements/GAvtar";
import useWindowSize from "../DataFactory/useWindowSize";
import Modal from "react-modal";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
// import GConfirmDialog from "../gComponents/gConfirmDialog";
import { SettingsContext } from "../DataFactory/useSettings";
import { ReactComponent as ArrowRightIcon } from "../assets/images/icons/arrowRight.svg";
import { ReactComponent as ArrowDown } from "../assets/images/arrowDown.svg";
import GText from "../Elements/GText";

const Header = styled.header`
  background: #141618;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  box-sizing: border-box;
  // padding: 6px 12px;
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  display: block;
`;

const MenuSection = styled.div`
  position: relative;
  span {
    display: flex;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    padding: 25px 0;
    p {
      font-size: 18px !important;
    }
    svg {
      width: 12px;
      height: 14px;
      margin-left: 10px;
      fill: #fff;
    }
  }
  .subSection {
    display: none;
    position: absolute;
    background: #1b1d1f;
    left: 0;
    top: 68px;
    min-width: 160px;
    box-shadow: 0 0 13px #141618;
    padding: 0 12px;
    ul {
      list-style-type: none;
      margin: 10px 0;
      padding: 0;
      li {
        margin: 2px 0;
        padding: 6px 8px;
        font-size: 16px;
        a {
          display: block;
          color: #aaa;
          text-decoration: none;
        }
        &:hover {
          background: #333;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  &:hover .subSection {
    display: block;
  }
`;

const LinksView = styled.header`
  a {
    color: #fff;
    border: 2px solid #fff;
    background: transparent;
    display: inline-flex;
    margin: 0 10px;
    font-weight: 600;
    padding: 6px 10px 9px;
    align-items: end;

    &:hover {
      background: ${({ theme }) => theme.body};
    }

    svg {
      width: 18px;
      height: 18px;
      fill: #fff;
      margin-left: 10px;
    }
  }
`;

const LinkDropdown = styled.div`
  position: absolute;
  background: #1b1d1f;
  right: 15px;
  top: 54px;
  min-width: 200px;
  box-shadow: 0 0 13px #141618;
  border-top: 3px solid ${({ theme }) => theme.body};
  padding: 10px 0;

  &::before {
    content: "";
    position: absolute;
    background: #1b1d1f;
    border-color: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.body};
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-width: 3px;
    padding: 7px;
    margin-left: -10px;
    transform: rotate(45deg);
    top: -10px;
    right: 30px;
    z-index: 1;
  }
  ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    li {
      a {
        padding: 8px 20px;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        display: block;
        text-align: left;

        &:hover {
          background: #333;
          color: #fff;
        }
      }
    }
  }
`;

const HeaderView = () => {
  const { settings } = useContext(SettingsContext);
  const { width } = useWindowSize();
  const dropdownRef = useRef(null);
  const [open, setOpen] = useDetectOutsideClick(dropdownRef, false);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  const handleLogout = () => {
    LogoutService("c/logout")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.setItem("wAccessToken", "");
          localStorage.setItem("wRefreshToken", "");
          localStorage.setItem("wExpiresIn", 0);
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Header style={{ minHeight: "40px" }}>
      <div
        className="main-content"
        style={{ marginTop: "0", marginBottom: "0" }}
      >
        <GView style={{ display: "flex", padding: "0", alignItems: "center" }}>
          <Link
            to="/"
            style={{
              display: "inline-flex",
              height: "100%",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            {settings?.web?.web_logo ? (
              <img
                src={ImgUrl("web") + "/" + settings?.web?.web_logo}
                className="header-logo"
                alt={settings?.project_name}
                // onError={addDefaultLogo}
                style={{ maxWidth: "180px", maxHeight: "40px" }}
              />
            ) : (
              <GText g2="true" bold theme text={"HOME"} />
            )}
          </Link>
          {width > 767 && (
            <GView style={{ margin: "0 25px" }}>
              <MenuSection>
                <span>
                  <GText
                    semi
                    text={"About"}
                    style={{
                      color: "#fff",
                    }}
                  />
                  <ArrowDown />
                </span>
                <GView className="subSection">
                  <ul>
                    <li>
                      <Link to={"/terms"}>Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={"/faqs"}>FAQ's</Link>
                    </li>
                  </ul>
                </GView>
              </MenuSection>
            </GView>
          )}
          <GView style={{ marginLeft: "auto" }}>
            <GView style={{ position: "relative" }}>
              {settings ? (
                !settings?.user?.id ? (
                  <LinksView>
                    <a
                      onClick={() => setSigninvisible(true)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <UserIcon /> */}
                      Login <ArrowRightIcon />
                    </a>

                    <a
                      onClick={() => setSignupvisible(true)}
                      style={{ cursor: "pointer" }}
                    >
                      Signup <ArrowRightIcon />
                    </a>
                  </LinksView>
                ) : (
                  <a
                    onClick={() => setOpen(true)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <GAvtar
                      title={settings?.user?.title}
                      width={"40px"}
                      height={"40px"}
                      src={
                        settings?.user?.thumb_photo
                          ? ImgUrl("user") + "/" + settings?.user?.thumb_photo
                          : ""
                      }
                      imgType="user"
                      alt={settings?.user?.title}
                    />
                    &nbsp;&nbsp;
                    <GText
                      text={settings?.user?.title}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100px",
                        textAlign: "left",
                        color: "#fff",
                      }}
                    />
                  </a>
                )
              ) : (
                ""
              )}

              {open === true ? (
                <LinkDropdown ref={dropdownRef}>
                  <ul>
                    <li>
                      <Link to="/profile/orders" onClick={() => setOpen(false)}>
                        Manage Profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/orders" onClick={() => setOpen(false)}>
                        Orders
                      </Link>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setCpvisible(true);
                          setOpen(false);
                        }}
                      >
                        Change Password
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setOpen(false);
                          //setDialogOpen(true);
                          handleLogout();
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </LinkDropdown>
              ) : (
                ""
              )}
            </GView>
          </GView>
        </GView>
      </div>
      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={epvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "380px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "310px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </Header>
  );
};

export default HeaderView;
