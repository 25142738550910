import React, { useEffect, useState } from "react";
import GText from "../Elements/GText";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GButton from "../Elements/GButton";
import { GCardShimmer } from "../Elements/gShimmer";
import useWindowSize from "../DataFactory/useWindowSize";
import BannerBackgound from "../assets/images/homepage_banner_bg.jpg";
import BannerImg from "../assets/images/homepage_banner.png";
import CoOpLogo from "../assets/images/logos_co_op.png";
import HelloFreshLogo from "../assets/images/logos_hello-fresh.png";
import ScrewFixLogo from "../assets/images/logos_screwFix.png";
import QuredLogo from "../assets/images/logos_qured.png";
import SelfridgesLogo from "../assets/images/logos_selfridges.png";
import NetAPorterLogo from "../assets/images/logos_net-a-porter.png";
import FlowardLogo from "../assets/images/logos_floward.png";
import BootsLogo from "../assets/images/logos_boots.png";
import GreensonLogo from "../assets/images/logos_grenson.png";
import DiorLogo from "../assets/images/logos_dior.png";
import BusinessIcon from "../assets/images/homepage_business_icon.png";
import BusinessImg from "../assets/images/homepage_business_img.png";
import CourierIcon from "../assets/images/homepage_courier_icon.png";
import CourierImg from "../assets/images/homepage_courier_img.png";

const BannerSection = styled.div`
  .content {
    width: 50%;
    padding-bottom: 70px;
  }
  p {
    color: #fff !important;
  }
  @media (max-width: 991px) {
    .content {
      width: 100%;
    }
  }
`;

const ProductDiv = styled.div`
  padding: 50px 0;
  display: flex;
  .innerDiv {
    flex: 1;
    padding: 35px 30px;
    border: 1px solid #fff;

    p {
      color: #fff !important;
    }
  }
  @media (max-width: 991px) {
    .innerDiv {
      border: 0;
      padding: 20px;
    }
  }
`;

const BusinessLogoDiv = styled.div`
  padding: 30px 0;
  .grid {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    gap: 25px;
    img {
      width: 100%;
    }
  }
  hr {
    border: 0;
    border-bottom: 1px solid #000;
  }
  @media (max-width: 767px) {
    .grid {
      grid-template-columns: repeat(auto-fit, minmax(150px, 2fr));
    }
  }
`;

const ContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .img {
    width: 50%;
    text-align: center;
    background: #fff;
    img {
      max-width: 100%;
      padding: 5px 20px;
      box-sizing: border-box;
    }
  }

  .text {
    padding: 20px 30px;
    width: 50%;
    box-sizing: border-box;
    background: #000;
    .title {
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
      }
    }
    p {
      color: #fff !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .text {
      padding: 50px;
    }
    .img {
      img {
        max-width: 95%;
      }
    }
  }
  @media (min-width: 1201px) and (max-width: 1500px) {
    .text {
      padding: 70px;
    }
    .img {
      img {
        max-width: 90%;
      }
    }
  }

  @media (min-width: 1501px) and (max-width: 1700px) {
    .text {
      padding: 90px;
    }
    .img {
      img {
        max-width: 85%;
      }
    }
  }
  @media (min-width: 1701px) and (max-width: 1900px) {
    .text {
      padding: 115px;
    }
    .img {
      img {
        max-width: 80%;
      }
    }
  }

  @media (min-width: 1901px) {
    .text {
      padding: 125px;
    }
    .img {
      img {
        max-width: 70%;
      }
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
    .img {
      width: 100%;
      img {
        max-width: 100%;
        padding: 20px 30px;
      }
    }
    .text {
      padding: 50px 40px;
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .text {
      padding: 50px 30px;
    }
  }
`;

export default function HomeStaticContent(props) {
  const { width } = useWindowSize();

  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          backgroundImage: width > 991 ? `url(${BannerBackgound})` : "none",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingTop: width > 991 ? "100px" : "50px",
        }}
      >
        <div className="main-content">
          <BannerSection>
            <div className="content">
              <GText
                bold
                g1={"true"}
                text={"Same day couriers, reinvented"}
                style={{
                  fontSize: width > 992 ? "45px" : "32px",
                  lineHeight: width > 992 ? "55px" : "42px",
                }}
              />
              <GText
                text={
                  "We use cutting-edge tech to provide quick, convenient and reliable last-mile delivery solutions across London and the UK for all kinds of business; from household name retail brands to local independent restaurants."
                }
                style={{
                  margin: "25px 0 35px",
                  lineHeight: "27px",
                  letterSpacing: "0.4px",
                }}
              />
              {props.settings?.user?.id ? (
                <Link
                  to={"/add-order"}
                  style={{
                    margin: "20px 0 auto",
                  }}
                >
                  <GButton
                    variant="condensed"
                    children="Create New Order"
                    style={{
                      fontWeight: "600",
                      padding: "12px 30px",
                      boxShadow:
                        "0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)",
                    }}
                  />
                </Link>
              ) : (
                <GButton
                  variant="condensed"
                  children="Create New Order"
                  style={{
                    margin: "20px 0 auto",
                    fontWeight: "600",
                    padding: "12px 30px",
                    boxShadow:
                      "0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)",
                  }}
                  onClick={() => {
                    props.setSigninvisible(true);
                  }}
                />
              )}
            </div>
            {width <= 991 && (
              <div className="image">
                <img src={BannerBackgound} style={{ maxWidth: "100%" }} />
              </div>
            )}
          </BannerSection>
        </div>
      </div>

      <div style={{ background: "#000" }}>
        <div className="main-content" style={{ marginTop: 0 }}>
          <ProductDiv
            style={{
              flexDirection: width <= 991 ? "column" : "row",
              gap: width <= 991 ? "20px" : "50px",
            }}
          >
            <div className="innerDiv">
              <GText
                g1="true"
                bold
                text={"For bookings"}
                style={{
                  fontSize: width > 767 ? "34px" : "28px",
                  lineHeight: width > 992 ? "42px" : "36px",
                }}
              />
              <GText
                text={
                  "Arrange a delivery for your package or enter some of your requirements to get a quick quote and see how our rates compare."
                }
                style={{ margin: "20px 0 30px", lineHeight: "25px" }}
              />
              {props.settings?.user?.id ? (
                <Link
                  to={"/add-order"}
                  style={{
                    margin: "20px 0 auto",
                  }}
                >
                  <GButton variant="condensed" children="Create Order" />
                </Link>
              ) : (
                <GButton
                  variant="condensed"
                  children="Create Order"
                  onClick={() => {
                    props.setSigninvisible(true);
                  }}
                />
              )}
            </div>
            <div className="innerDiv">
              <GText
                g1="true"
                bold
                text={"For businesses"}
                style={{
                  fontSize: width > 767 ? "34px" : "28px",
                  lineHeight: width > 992 ? "42px" : "36px",
                }}
              />
              <GText
                text={
                  "Find out how we can partner up to provide last-mile delivery solutions that work for you and your customers."
                }
                style={{ margin: "20px 0 30px", lineHeight: "25px" }}
              />
              {/* <GButton variant="condensed">Create Order</GButton> */}
            </div>
            <div className="innerDiv">
              <GText
                g1="true"
                bold
                text={"For couriers"}
                style={{
                  fontSize: width > 767 ? "34px" : "28px",
                  lineHeight: width > 992 ? "42px" : "36px",
                }}
              />
              <GText
                text={
                  "Some of the best couriers work with us because we offer flexible hours, great pay and fair treatment. Apply in just 10 minutes."
                }
                style={{ margin: "20px 0 30px", lineHeight: "25px" }}
              />
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdHdV4rfH2G2cmrsEiMw18Ea6X0fsNmljp_1sp-tYGBNzOuSw/viewform?usp=sf_link"
                target="_blank"
              >
                <GButton variant="condensed">Join now</GButton>
              </a>
            </div>
          </ProductDiv>
        </div>
      </div>

      {/* <div style={{ borderBottom: "1px solid #000", padding: "50px 0" }}>
        <div className="main-content">
          <GText
            g3
            semi
            text={"DELIVERING FOR GREAT BUSINESSES"}
            style={{ textTransform: "uppercase", letterSpacing: "2px" }}
          />

          <BusinessLogoDiv>
            <hr />
            <div className="grid">
              <img src={CoOpLogo} />
              <img src={HelloFreshLogo} />
              <img src={ScrewFixLogo} />
              <img src={QuredLogo} />
              <img src={SelfridgesLogo} />
              <img src={NetAPorterLogo} />
              <img src={FlowardLogo} />
              <img src={BootsLogo} />
              <img src={GreensonLogo} />
              <img src={DiorLogo} />
            </div>
            <hr />
          </BusinessLogoDiv>
        </div>
      </div> */}
      <div>
        <ContentDiv>
          <div className="text">
            <div className="title">
              <img src={BusinessIcon} />{" "}
              <GText
                g1={"true"}
                bold
                text={"BUSINESS"}
                style={{
                  fontSize: width > 767 ? "32px" : "28px",
                  letterSpacing: "2px",
                }}
              />
            </div>
            <GText
              g2={"true"}
              semi
              text={"Total transparency, more control"}
              style={{
                margin: width > 767 ? "30px 0" : "15px 0",
                fontSize: width > 767 ? "28px" : "24px",
              }}
            />
            <p
              style={{
                padding: "15px 0 30px",
                lineHeight: "25px",
                fontSize: width > 767 ? "15px" : "14px",
                letterSpacing: "0.5px",
              }}
            >
              With total oversight every step of the way,{width > 991 && <br />}{" "}
              our fleet of bicycles, motorcycles and vans{width > 991 && <br />}{" "}
              are ready to meet your express delivery needs. <br />
              <br /> Simply book when best suits you and our local couriers
              {width > 991 && <br />} will pickup on demand. All you have to do
              is outline{width > 991 && <br />} the package you need delivered
              and we'll suggest the fastest,{width > 991 && <br />} cheapest and
              most environmentally friendly option.
            </p>

            <GButton
              variant="condensed"
              children={"Create Order"}
              style={{
                fontSize: "17px",
                borderRadius: "5px",
                padding: "0.8rem 4rem",
                cursor: "auto",
              }}
            />
          </div>
          <div
            className="img"
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={BusinessImg}
              alt={"Business"}
              width="auto"
              height="auto"
            />
          </div>
        </ContentDiv>
      </div>

      <div>
        <ContentDiv>
          <div
            className="img"
            style={{
              textAlign: "center",
              order: width <= 991 ? 2 : 0,
            }}
          >
            <img src={CourierImg} alt={"Couriers"} width="auto" height="auto" />
          </div>
          <div className="text">
            <div className="title">
              <img src={CourierIcon} />{" "}
              <GText
                g1={"true"}
                bold
                text={"COURIERS"}
                style={{
                  fontSize: width > 767 ? "32px" : "28px",
                  letterSpacing: "2px",
                }}
              />
            </div>
            <GText
              g2={"true"}
              semi
              text={"More opportunities, less hassle"}
              style={{
                margin: width > 767 ? "30px 0" : "15px 0",
                fontSize: width > 767 ? "28px" : "24px",
              }}
            />
            <p
              style={{
                padding: "15px 0 30px",
                lineHeight: "25px",
                fontSize: width > 767 ? "15px" : "14px",
                letterSpacing: "0.5px",
              }}
            >
              Receive total transparency on all job info.
              {width > 991 && <br />}
              Get paid by distance, punctuality, and weight.
              {width > 991 && <br />} Reduce admin whilst supercharging your
              customer relationships. <br /> <br /> Have constant support from
              our helpful {width > 991 && <br />}team and have flexibility like
              nowhere else.{width > 991 && <br />} Choose when and where you
              want to work.{width > 991 && <br />} Being a self-employed courier
              has never been easier.
            </p>

            <GButton
              variant="condensed"
              children={"Order Now"}
              style={{
                fontSize: "17px",
                borderRadius: "5px",
                padding: "0.8rem 4rem",
                cursor: "auto",
              }}
            />
          </div>
        </ContentDiv>
      </div>
    </>
  );
}
