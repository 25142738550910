import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { ApiService } from "../services";
import PaymentMethod from "../paymentComponents/checkoutPaymentMethods";
import OrderWallet from "../Components/checkoutWallet";
import ApplyCoupon from "../Components/checkoutOrderCoupon";
import GView from "../Elements/GView";
import { useSnackbar } from "notistack";
import { SettingsContext } from "../DataFactory/useSettings";
import { GTableContent } from "../Elements/gContent";
import GText from "../Elements/GText";
import GButton from "../Elements/GButton";
import OrderPlaced from "../Components/orderPlaced";
import Modal from "react-modal";
import WebPaymentCheckout from "../paymentComponents/webPaymentCheckout";

const OrderCheckout = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [initialLoading, setInitialLoading] = useState(true);
  const { settings } = useContext(SettingsContext);
  const [COrderData, setCOrderData] = useState(props.data || {});
  const [useWallet, setUseWallet] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [couponVisible, setCouponVisible] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [checkoutData, setCheckoutData] = useState({
    summary: {
      data: [],
    },
    wallet: {},
  });
  const [cPaymentMethod, setCPaymentMethod] = useState({});
  const [gateways, setGateways] = useState({
    card: {},
    gateways: [],
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [placedOrderId, setOrderId] = useState("");
  const [webPaymentVisible, setWebPaymentVisible] = useState(false);
  const [webPaymentUrl, setWebPaymentUrl] = useState({});

  useEffect(() => {
    console.log(props);
    setUseWallet(false);
    setSelectedCoupon("");
    setCOrderData(props.data || {});
  }, []);

  useEffect(() => {
    console.log(props);
    if (initialLoading === true) {
      ApiService({
        method: "GET",
        route: `c/gateways`,
        body: { amount: checkoutData?.summary?.total },
      }).then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setGateways(response.data.data);
        } else {
          return;
        }
      });
    }
  }, [checkoutData]);

  useEffect(() => {
    console.log(COrderData);
    ApiService({
      method: "POST",
      route: "c/order/checkout",
      body: COrderData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setCheckoutData(response.data.data);
          setCouponVisible(false);
          setIsDisabled(false);
        } else {
          setCheckoutData(response.data.data);
          if (couponVisible === true) {
            //setSelectedCoupon("");
            setIsCouponValid(false);
          }
          setIsDisabled(true);
          enqueueSnackbar(response.data.message);
        }
        setInitialLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [COrderData]);

  useEffect(() => {
    console.log(selectedCoupon);
    if (initialLoading === false) {
      COrderData.coupon_code = selectedCoupon;
      COrderData.points = useWallet;
      setCOrderData({ ...COrderData });
    }
  }, [selectedCoupon, useWallet]);

  useEffect(() => {
    console.log(cPaymentMethod);

    if (initialLoading == false && gateways.length > 0) {
      if (cPaymentMethod?.identifier) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [cPaymentMethod]);

  const placeOrder = () => {
    if (!cPaymentMethod?.gateway_type) {
      enqueueSnackbar("Please Choose a Payment Method");
      return;
    }
    console.log(cPaymentMethod);
    if (cPaymentMethod.gateway_type === "web") {
      getWebToken();
    } else {
      finalOrderPlaced();
    }
  };

  const getWebToken = () => {
    ApiService({
      method: "POST",
      route: `c/webpayments/token`,
      body: {
        amount: checkoutData.summary.total,
        gateway: cPaymentMethod.identifier,
        callback_url: process.env.REACT_APP_BASE_URL + "/add-order",
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setWebPaymentUrl(response.data.data);
        setCPaymentMethod({
          ...cPaymentMethod,
          token: response.data.data.token,
        });
        setWebPaymentVisible(true);
      } else {
        enqueueSnackbar(response.data.message);
        return;
      }
    });
  };

  function finalOrderPlaced() {
    setBtnLoading(true);
    const placedData = { ...COrderData };
    placedData.gateway = cPaymentMethod || {};
    console.log(placedData);
    //return;
    ApiService({ method: "POST", route: "c/orders", body: placedData }).then(
      (response) => {
        console.log("r" + response.status_code);
        if (response.data.status_code === 1) {
          setOrderId(response.data.data.id);
          setShowOrderSuccess(true);
        } else {
          enqueueSnackbar(response.data.message);
          setBtnLoading(false);
          return;
        }
      }
    );
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setCheckoutVisible(false)}
        />
        Payment
      </div>
      <div
        className="modal-body"
        style={{ padding: "0", height: "calc(100vh - 130px)" }}
      >
        <GText
          g6
          dark
          text={"Payment Method"}
          style={{
            padding: "15px 25px 7px",
            background: "#f4f5f9",
            textTransform: "uppercase",
          }}
        />
        <PaymentMethod
          paymentGateways={gateways}
          cPaymentMethod={cPaymentMethod}
          onSelecteGateway={(gateway) => {
            console.log(gateway);
            setCPaymentMethod(gateway);
          }}
          setCPaymentMethod={setCPaymentMethod}
          orderTotal={
            settings.currency_symbol + "" + checkoutData?.summary?.total_display
          }
        />

        <GText
          g6
          dark
          text={"Promo Code"}
          style={{
            padding: "15px 25px 7px",
            background: "#f4f5f9",
            textTransform: "uppercase",
          }}
        />
        <ApplyCoupon
          selectedCoupon={selectedCoupon}
          setSelectedCoupon={setSelectedCoupon}
          setIsCouponValid={setIsCouponValid}
          isCouponValid={isCouponValid}
          couponVisible={couponVisible}
          setCouponVisible={setCouponVisible}
        />

        {checkoutData?.wallet?.points ? (
          <GView>
            <GText
              g6
              dark
              text={"Use Wallet Cash"}
              style={{
                padding: "15px 25px 7px",
                background: "#f4f5f9",
                textTransform: "uppercase",
              }}
            />
            <OrderWallet
              useWallet={useWallet}
              setUseWallet={setUseWallet}
              wallet={checkoutData?.wallet?.points}
              balance={settings?.currency_symbol + checkoutData?.wallet?.amount}
              text={checkoutData?.wallet?.text}
            />
          </GView>
        ) : (
          ""
        )}

        {checkoutData ? (
          <React.Fragment>
            <GText
              g6
              dark
              text={"Payment Summary"}
              style={{
                padding: "15px 25px 7px",
                background: "#f4f5f9",
                textTransform: "uppercase",
              }}
            />

            <GView style={{ padding: "10px 20px 0" }}>
              {checkoutData?.summary?.data.map((p) => {
                return (
                  <GTableContent
                    key={p.title}
                    title={p.title}
                    value={settings.currency_symbol + "" + p.value}
                    align="right"
                    lAlign="right"
                    color="#333"
                    padding="2px 5px"
                  />
                );
              })}
            </GView>
          </React.Fragment>
        ) : (
          ""
        )}

        <GView style={{ padding: "0 20px 10px" }}>
          <>
            <hr
              style={{
                border: "0",
                borderBottom: "1px solid #e2e2e2",
                margin: "5px 0",
              }}
            />
            <GTableContent
              title="Grand Total"
              value={
                settings.currency_symbol + checkoutData?.summary?.total_display
              }
              align="right"
              lAlign="right"
              bold={true}
            />{" "}
          </>
        </GView>
      </div>
      <div className="modal-footer">
        <GButton
          variant="condensed"
          disabled={isDisabled}
          loading={isBtnLoading}
          style={{
            width: "calc(100% - 50px)",
            margin: "20px 25px",
            textAlign: "left",
            position: "fixed",
            bottom: "0",
            textTransform: "uppercase",
            fontWeight: "550",
            fontSize: "14px",
            padding: "0.9em 1.6em",
            borderRadius: "2px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => placeOrder()}
        >
          Place Order
          <span
            style={{
              marginLeft: "auto",
              fontWeight: "600",
              float: "right",
            }}
          >
            {settings.currency_symbol + checkoutData?.summary?.total_display}
          </span>
        </GButton>
      </div>

      <Modal
        isOpen={showOrderSuccess}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowOrderSuccess(true)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderPlaced
          orderId={placedOrderId}
          showOrderSuccess={showOrderSuccess}
        />
      </Modal>

      {webPaymentVisible == true && (
        <WebPaymentCheckout
          selectedGateway={cPaymentMethod}
          setWebPaymentVisible={setWebPaymentVisible}
          tokenData={webPaymentUrl}
          orderId={placedOrderId}
          amount={checkoutData?.summary?.total}
          onSuccessPayment={() => {
            setWebPaymentVisible(false);
            finalOrderPlaced();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default OrderCheckout;
