import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { date } from "yup/lib/locale";

function GdatePicker(props) {
  const { label, name, id, value, format, minDate,maxTime,minTime, ...rest } = props;

  return (
    <React.Fragment>
      {label ? <label htmlFor={name}>{label}</label> : ""}
      <DatePicker
        id={id}
        placeholderText={'Select'}
        selected={value}
        dateFormat={format}
        onChange={(date) => props.onChange(date)} 
        // onSelect={(date) => props.onSelect(date)}
        minDate={minDate || null}
        minTime={minTime || null}
        maxTime={maxTime || null}
        showTimeSelect={props.showTimeSelect || false}
      />
    </React.Fragment>
  );
}

export default GdatePicker;
