import React, { useContext, useState, useEffect } from "react";
import { ApiLoginService, ImgUrl } from "../services";
import GView from "../Elements/GView";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import PlayStoreBadge from "../assets/images/google-play-badge.png";
import AppStoreBadge from "../assets/images/app-store-badge.png";
import { ReactComponent as PhoneIcon } from "../assets/images/icons/phone.svg";
import { ReactComponent as MailIcon } from "../assets/images/icons/mail.svg";
import useLocalStorage from "../DataFactory/useLocalStorage";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import useWindowSize from "../DataFactory/useWindowSize";

const PagesList = [
  { title: "Terms & Conditions", link: "/terms" },
  { title: "Privacy Policies", link: "/privacy-policy" },
  { title: "FAQ's", link: "/faqs" },
  { title: "Offers", link: "/offers" },
];

const Footer = styled.div`
  margin-top: 20px;
  // border-top: 3px solid ${({ theme }) => theme.body};
  background: #141618;
  a {
    text-decoration: none;
    p {
      color: #aaa !important;
    }
    &:hover p {
      color: #fff !important;
    }
    &.theme {
      color: ${({ theme }) => theme.body};
      font-weight: 600;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    &.apps {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      li {
        padding: 0;
        a {
          margin: 5px 0 10px;
          display: block;

          img {
            max-width: 100%;
            width: 135px;
            border: 1px solid #eee;
            border-radius: 4px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      &.apps {
        justify-content: center;
      }
    }
  }
`;
export default function FooterView() {
  const { settings } = useContext(SettingsContext);
  const { width } = useWindowSize();
  const location = useLocation();
  const [socialLinks, setSocialLinks] = useLocalStorage("social", []);
  useEffect(() => {
    if (socialLinks && socialLinks.length > 0) {
      return;
    }
    ApiLoginService("GET", "c/social")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setSocialLinks(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Footer
      style={{
        marginTop:
          location.pathname === "/add-order" || location.pathname === "/"
            ? "0"
            : "20px",
      }}
    >
      <div className="main-content">
        {settings?.web?.web_logo && (
          <GView style={{ padding: "25px 0 0" }}>
            <Link
              to="/"
              style={{
                display: "inline-flex",
                height: "100%",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              <img
                src={ImgUrl("web") + "/" + settings?.web?.web_logo}
                className="header-logo"
                alt={settings?.project_name}
                // onError={addDefaultLogo}
                style={{ maxWidth: "250px", maxHeight: "100px" }}
              />
            </Link>
          </GView>
        )}
        <GView
          style={{
            display: "flex",
            padding: "10px 0 20px",
            flexDirection: width <= 767 ? "column" : "row",
          }}
        >
          <GView
            style={{
              flex: "1 1 70%",
              textAlign: width <= 767 ? "center" : "left",
            }}
          >
            <br />
            <a
              style={{
                display: "flex",
                margin: "10px 0",
                justifyContent: width <= 767 ? "center" : "left",
              }}
              href={"mailto:" + settings?.admin_email}
            >
              <MailIcon
                style={{ width: "20px", marginRight: "10px", fill: "#555" }}
              />
              <GText dark text={settings?.admin_email}></GText>
            </a>
            <a
              style={{
                display: "flex",
                margin: "10px 0",
                justifyContent: width <= 767 ? "center" : "left",
              }}
              href={"mailto:" + settings?.admin_phone}
            >
              <PhoneIcon
                style={{ width: "20px", marginRight: "10px", fill: "#555" }}
              />
              <GText dark text={settings?.admin_phone}></GText>
            </a>
          </GView>
          <GView
            style={{ flex: "50%", textAlign: width <= 767 ? "center" : "left" }}
          >
            <GText
              g5
              semi
              text={"Pages"}
              style={{
                margin: "15px 0",
                color: "#fff",
                textTransform: "uppercase",
              }}
            />
            <ul>
              {PagesList.map((p, i) => {
                return (
                  <li key={i} style={{ padding: "5px 0" }}>
                    <Link to={p.link}>
                      <GText dark text={p.title} />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </GView>
          {socialLinks && socialLinks.length > 0 ? (
            <GView
              style={{
                flex: "50%",
                textAlign: width <= 767 ? "center" : "left",
              }}
            >
              <GText
                g5
                semi
                text={"Follow us"}
                style={{
                  margin: "15px 0",
                  color: "#fff",
                  textTransform: "uppercase",
                }}
              />
              <ul>
                {socialLinks.map((s, i) => {
                  return (
                    <li key={i} style={{ padding: "5px 0" }}>
                      <a href={s.url} target="_blank" rel="noopener noreferrer">
                        <GText dark text={s.title} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </GView>
          ) : (
            ""
          )}
          <GView
            style={{
              flex: "50%",
              textAlign: width <= 767 ? "center" : "left",
            }}
          >
            <GText
              g5
              semi
              text={"Download Apps"}
              style={{
                margin: "15px 0",
                color: "#fff",
                textTransform: "uppercase",
              }}
            />
            <ul className="apps">
              <li>
                <a
                  href={settings?.i_c_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={AppStoreBadge} />
                </a>
                <a
                  href={settings?.a_c_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={PlayStoreBadge} />
                </a>
              </li>
            </ul>
          </GView>
        </GView>
        <GView
          style={{
            display: "flex",
            padding: "20px 0",
            borderTop: "1px solid #e2e2e2",
            flexDirection: width <= 767 ? "column" : "row",
            justifyContent: width <= 767 ? "center" : "",
          }}
        >
          <GText
            text={
              "(c) " + new Date().getFullYear() + " JHL. All Rights Reserved"
            }
            style={{
              color: "#aaa",
              width: "auto",
              margin: width <= 767 ? "0 auto" : "0",
            }}
          />
          <p
            style={{
              margin: width > 767 ? "0 0 0 auto" : "5px auto 0 ",
              fontSize: "14px",
              color: "#aaa",
            }}
          >
            Powered by{" "}
            <a
              // href="https://www.goteso.com"
              // target="_blank"
              rel="noopener noreferrer"
              className="theme"
            >
              JHL
            </a>
          </p>
        </GView>
      </div>
    </Footer>
  );
}
