import React, { useState, useEffect } from "react";
import GMap from "./orderLocation";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as ChatIcon } from "../assets/images/chat.svg";
import { ImgUrl, addDefaultSrc } from "../services";
import GText from "../Elements/GText";
import GInfo from "../Elements/gInfo";
import GButton from "../Elements/GButton";

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

const App = (props) => {
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  return (
    <div>
      {!loadMap ? (
        <div>Loading...</div>
      ) : (
        <GMap map={true} data={props.taskData} />
      )}
      <div style={{ padding: "18px 0 8px" }}>
      
        <h4 style={{ margin: "5px 0", fontWeight: "500", fontSize: "15px" }}>
          {props.status?.title}{" "}
          <span
            style={{
              background: "#" + props.status?.color1,
              height: "8px",
              width: "8px",
              display: "inline-block",
              marginLeft: "3px",
              borderRadius: "20px",
            }}
          ></span>
        </h4>

        {props.agent?.id && (props.status?.title !== "Cancelled" && props.status?.title !== "Completed")&&(
         <div  style={{
          float: "right",
          marginRight: "10px",display:'inline-flex',alignItems:'center',
          position: "relative",
        }}>
           
             <a
            target="_blank"
            rel="noopener noreferrer"
            href={"tel:" + props.agent?.phone}
            style={{
              cursor: "pointer",
              position: "relative",
            }}
          >
            <PhoneIcon
              style={{
                width: "25px",
                height: "25px",
              }}
            />
          </a>
          <GButton variant="linkable" onClick={() => props.setOmvisible(true)}><ChatIcon
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        cursor: "pointer",
                                      }}
                                    /></GButton>
          </div>
        )}

        {props.agent?.id ? <GInfo
                      title={props.agent?.title}
                      subtitle={props.agent?.phone}
                      photo={props.agent?.thumb_photo}
            imgType="user"
            width={'50px'}
            style={{width:'auto'}}
                    />
       : <GText
          g6
          text={
            "Delivery Agent : Not Assigned yet"
          }
        />}
      </div>
    </div>
  );
};

export default App;
