import React, { useEffect, useContext } from "react";
import "./App.css";
import "./assets/css/modal.css";
import "./assets/css/forms.css";
import Header from "./Parts/header";
import Footer from "./Parts/footer";
import Home from "./Pages/home";
import OrderCreate from "./Pages/OrderCreate";
import PrivacyPolicy from "./Pages/privacyPolicy";
import Terms from "./Pages/terms&Conditions";
import Offers from "./Pages/offers";
import Faqs from "./Pages/faqs";
import Profile from "./Pages/profile";
import { Route, Routes, useLocation } from "react-router-dom";
import { SettingsContext } from "./DataFactory/useSettings";
import { ThemeStore } from "./DataFactory/useTheme";
import Theme from "./DataFactory/theme";
import useLocalStorage from "./DataFactory/useLocalStorage";
import Modal from "react-modal";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./errorView";
import NotFound from "./notFound";
import { ImgUrl } from "./services";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}

function App() {
  const location = useLocation();
  const [setting] = useLocalStorage("settings", {});
  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(location);
  }, [location]);

  const isSettings = () => {
    if (settings && settings?.currency_symbol) {
      document.title = settings?.project_name || "JHL";
      //grab favicon element by ID
      const favicon = document.getElementById("favicon");
      if (settings?.web?.web_favicon) {
        favicon.href = ImgUrl("web") + "/" + settings?.web?.web_favicon;
        setTimeout(function () {
          favicon.href = ImgUrl("web") + "/" + settings?.web?.web_favicon;
        }, 800);
      }
      return true;
    }
  };

  Modal.setAppElement("#root");
  return (
    <ThemeStore>
      <Theme>
        <div className="App" id="webElement">
          <div style={{ position: "relative", padding: "35px 0" }}>
            <Header />
          </div>

          {isSettings() === false ? (
            <NotFound />
          ) : (
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                window.location.reload(false);
              }}
            >
              <div
                className="App-content"
                style={{
                  flex: "1 1 auto",
                  paddingTop:
                    location.pathname === "/add-order" ||
                    location.pathname === "/"
                      ? "0"
                      : "20px",
                }}
              >
                <Routes>
                  <Route exact path="/" element={<Home />}></Route>
                  <Route
                    exact
                    path="/add-order"
                    element={<OrderCreate />}
                  ></Route>
                  {/* <Route path="/not-found" component={NotFound}></Route> */}
                  <Route
                    path="privacy-policy"
                    element={<PrivacyPolicy />}
                  ></Route>
                  <Route path="terms" element={<Terms />}></Route>
                  <Route path="offers" element={<Offers />}></Route>
                  <Route path="faqs" element={<Faqs />}></Route>
                  <Route path="profile/*" element={<Profile />}></Route>
                </Routes>
              </div>
            </ErrorBoundary>
          )}
          <Footer />
        </div>
      </Theme>
    </ThemeStore>
  );
}

export default App;
